import "@/css/index";
import "modaal";
import './lib/jquery.inview.min.js';

$(document).ready(function(){

  //header sticky
  var lastIsSticky = undefined,
    $document = $(document),
    $header = $(".l-globalheader");

  $(window).on("scroll", function() {
    var scroll = $document.scrollTop(),
      isSticky = scroll > 100;

    if (lastIsSticky === isSticky) {
      return;
    }
    lastIsSticky = isSticky;

    if (isSticky) {
      $header.addClass("is-sticky")
    }
    else {
      $header.removeClass("is-sticky")
    }
  });
  
  //modaal
  $(".js-modal-image").modaal({type:'image'});
  $(".js-modal-inline").modaal({type:'inline'});
  
  //anchor
  $('a[href^="#"]:not(.js-modal-inline)').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
  
  //drawer menu
	$('.l-spmenu-button').on('click', function() {
		if ($(this).hasClass('is-close')){
			$(this).removeClass('is-close');
			$('.l-spmenu-body').removeClass('is-open');
			$('html').removeClass('spmenu-open');
			$('.l-spmenu-overray').fadeOut();

		} else {
			$(this).addClass('is-close');
			$('.l-spmenu-body').addClass('is-open');
			$('html').addClass('spmenu-open');
			$('.l-spmenu-overray').fadeIn();
		}
	});
  
  $('.l-spmenu-body-navi a[href^="#"]').on('click', function() {
    $('.l-spmenu-button').removeClass('is-close');
    $('.l-spmenu-body').removeClass('is-open');
    $('html').removeClass('spmenu-open');
    $('.l-spmenu-overray').fadeOut();
	});
  
  //tab
  $('.u-tab li').click(function() {
    var index = $('.u-tab li').index(this);
    $('.u-tab li').removeClass('is-active');
    $(this).addClass('is-active');
    $('.u-tab-body-item').removeClass('is-show').eq(index).addClass('is-show');
  });
    
    
});

$(window).on('load', function() {
  
  //inview
  $('.is-ready').on('inview', function(event, isInView){
    if (isInView) {
      $(this).addClass('is-active');
    }
  });
  
});